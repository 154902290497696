@import "../../../../../public/mixins/mixins.scss";

.blogCardWrapper {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px #acdae9;
  padding: 15px;
  margin: 10px 10px;
  @include flexContainer(column, center, center, 15px);
  max-width: 355px;

  @media (max-width: 465px) {
    max-width: 315px;
  }

  @media (max-width: 420px) {
    max-width: 265px;
  }

  .blogImgContainer {
    width: 100%;
    height: 160px;
    position: relative;
    border-radius: 12px;
    overflow: hidden;

    .dateContainer {
      width: 110px;
      text-align: center;
      position: absolute;
      right: 0;
      top: 30px;
      background-color: #fff;
      border-radius: 20px 0 0 20px;
      padding: 6px 0px;
      @include font(12px, 500, #007a5a);
    }
  }
  .blogHeading {
    @include font(14px, 500, #4a536a, 0.56px);
  }
  .blogSubHeading {
    @include font(12px, 500, #666161, 0.48px);
  }
  .truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to show */
    -webkit-box-orient: vertical;
  }
  .viewMore {
    padding: 12px 55px;
    border-radius: 21px;
    border: 1px solid #cdcdcd;
    background: #fff;
    @include font(12px, 500, #4a536a);
  }
}
